import * as React from "react"
import { Link } from "gatsby"
import Seo from "../components/utils/seo"
import Layout from "../components/layout/layout"

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="Page not found">
        <meta name="robots" content="noindex" />
      </Seo>
      <title>Not found</title>
      <section className="mt-8 max-w-screen-xl mx-auto">
        <h1>Page not found</h1>
        <p>
          Sorry{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          we couldn’t find what you were looking for.
          <br />
          {process.env.NODE_ENV === "development" ? (
            <>
              <br />
              Try creating a page in <code>src/pages/</code>.
              <br />
            </>
          ) : null}
          <br />
          <Link to="/">Go home</Link>.
        </p>
      </section>
    </Layout>
  )
}

export default NotFoundPage
